import { useTranslations } from 'next-intl';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import classNames from 'classnames';

import SEO from '@/components/SEO';
import Layout from '@/components/Layout';
import Container from '@/components/Container';
import Heading from '@/components/Heading';
import InstructionSteps from '@/containers/index/InstructionSteps';
import Carousel from '@/components/Carousel/Carousel';
import BackendClient from '@/lib/api';
import routes from '@/lib/routes';
import { meta } from '@/lib/seo';
import Separator from '@/components/Separator';
import DARLING from '../assets/images/targeted-categories/DARLING.jpg';
import FRIEND from '../assets/images/targeted-categories/FRIEND.jpg';
import KID from '../assets/images/targeted-categories/KID.jpg';
import PARENT from '../assets/images/targeted-categories/PARENT.jpg';
import SubscribeBackground from '../assets/svg/subscribe-background.svg';
import SubscribeBackgroundChristmas2022 from '../assets/svg/subscribe-background-christmas2022.svg';
import yldine_kategooria_eripakkumine from '../assets/images/categories/yldine_kategooria_eripakkumine.jpg';
import yldine_kategooria_kultuurjaajalugu from '../assets/images/categories/yldine_kategooria_kultuurjaajalugu.jpg';
import yldine_kategooria_loodus from '../assets/images/categories/yldine_kategooria_loodus.jpg';
import yldine_kategooria_majutus from '../assets/images/categories/yldine_kategooria_majutus.jpg';
import yldine_kategooria_ostud from '../assets/images/categories/yldine_kategooria_ostud.jpg';
import yldine_kategooria_seminariruum from '../assets/images/categories/yldine_kategooria_seminariruum.jpg';
import yldine_kategooria_spaasaun from '../assets/images/categories/yldine_kategooria_spaasaun.jpg';
import yldine_kategooria_sportjaseiklus from '../assets/images/categories/yldine_kategooria_sportjaseiklus.jpg';
import yldine_kategooria_toit from '../assets/images/categories/yldine_kategooria_toit.jpg';
import yldine_kategooria_tuur from '../assets/images/categories/yldine_kategooria_tuur.jpg';
import yldine_kategooria_uritus from '../assets/images/categories/yldine_kategooria_uritus.jpg';
import north from '../assets/images/regions/north.jpg';
import south from '../assets/images/regions/south.jpg';
import west from '../assets/images/regions/west.jpg';
import islands from '../assets/images/regions/islands.jpg';
import tallinn from '../assets/images/regions/tallinn.jpg';
import GiftGenerator from '@/containers/index/GiftGenerator';
import useTheme, { THEME } from '@/hooks/useTheme';
import Maintenance from '@/components/Maintenance';

const getTargetedImage = (category) => {
  switch (category) {
    case 'DARLING':
      return DARLING;
    case 'FRIEND':
      return FRIEND;
    case 'KID':
      return KID;
    default:
    case 'PARENT':
      return PARENT;
  }
};

const getCategoryImage = (category) => {
  switch (category) {
    case 'yldine_kategooria_eripakkumine':
      return yldine_kategooria_eripakkumine;
    case 'yldine_kategooria_kultuurjaajalugu':
      return yldine_kategooria_kultuurjaajalugu;
    case 'yldine_kategooria_loodus':
      return yldine_kategooria_loodus;
    case 'yldine_kategooria_majutus':
      return yldine_kategooria_majutus;
    case 'yldine_kategooria_ostud':
      return yldine_kategooria_ostud;
    case 'yldine_kategooria_seminariruum':
      return yldine_kategooria_seminariruum;
    case 'yldine_kategooria_spaasaun':
      return yldine_kategooria_spaasaun;
    case 'yldine_kategooria_sportjaseiklus':
      return yldine_kategooria_sportjaseiklus;
    case 'yldine_kategooria_toit':
      return yldine_kategooria_toit;
    case 'yldine_kategooria_tuur':
      return yldine_kategooria_tuur;
    default:
    case 'yldine_kategooria_uritus':
      return yldine_kategooria_uritus;
  }
};

const getRegionImage = (region) => {
  switch (region) {
    case 'NORTH':
      return north;
    case 'SOUTH':
      return south;
    case 'WEST':
      return west;
    case 'ISLANDS':
      return islands;
    default:
    case 'TALLINN':
      return tallinn;
  }
};

const ContactForm = dynamic(() => import('@/containers/index/ContactForm'));
const Category = dynamic(() => import('@/containers/index/Category'));

function Home({ configuration }) {
  const t = useTranslations();
  const uri = routes.HOME;
  const router = useRouter();
  const { theme } = useTheme();

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return <Maintenance />;
  }

  const SubscribeBackgroundSVG = theme === THEME.DEFAULT ? SubscribeBackground : SubscribeBackgroundChristmas2022;

  return (
    <>
      <Layout pageTitle={meta.title} uri={uri} withoutPadding>
        <SEO uri={uri} meta={meta(t)} />

        <Container tag="section" className="-mb-24">
          <GiftGenerator targetedCategories={configuration.targetedCategories} categories={configuration.categories} />
        </Container>

        <div className="relative pt-24 pb-12 lg:pt-44 lg:pb-24 content-visibility">
          <div
            className={classNames('absolute left-0 right-0 bottom-0 top-0 clip-path-1', {
              'bg-gray-pahkla': theme === THEME.DEFAULT,
              'bg-secondary-tamula': theme === THEME.CHRISTMAS2022,
            })}
          ></div>
          <div
            className={classNames('absolute inset-0 clip-path-2 bg-opacity-50', {
              'bg-gray-pahkla': theme === THEME.DEFAULT,
              'bg-secondary-alutaguse z-[-1]': theme === THEME.CHRISTMAS2022,
            })}
          ></div>
          <div className="overflow-x-hidden">
            <Container className="relative z-[1] space-y-12 md:space-y-24">
              <InstructionSteps />
              <section className="relative space-y-8 md:space-y-12">
                <Heading
                  level="h2"
                  font="heading"
                  color={theme === THEME.DEFAULT ? 'primary' : 'national-park-vilsandi'}
                  className="md:text-center"
                >
                  {t('TARGETED_TITLE')}
                </Heading>
                <Carousel countDesktop={4}>
                  {configuration.targetedCategories.map((category) => {
                    const countKey = {
                      et: 'targetedCategoryCountsEt',
                      ru: 'targetedCategoryCountsRu',
                    }[router.locale];
                    const count = configuration[countKey]?.[category];

                    return (
                      <Link href={`${routes.FILTER[router.locale]}?targetedCategory=${category}`} key={category}>
                        <a className="space-y-4 group">
                          <figure className="relative aspect-w-3 aspect-h-4">
                            <Image
                              src={getTargetedImage(category)}
                              placeholder="blur"
                              layout="fill"
                              objectFit="cover"
                              alt={t(`TARGETED_CATEGORY_${category}`)}
                            />
                          </figure>

                          <p
                            className={classNames('font-bold no-underline group-hover:underline', {
                              'text-primary': theme === THEME.DEFAULT,
                              'text-national-park-vilsandi': theme === THEME.CHRISTMAS2022,
                            })}
                          >
                            {t(`TARGETED_CATEGORY_${category}`)}
                          </p>
                          {typeof count === 'number' && (
                            <span
                              className={classNames('text-sm', {
                                'text-gray-hellamaa': theme === THEME.DEFAULT,
                                'text-secondary-alutaguse': theme === THEME.CHRISTMAS2022,
                              })}
                            >
                              ({count})
                            </span>
                          )}
                        </a>
                      </Link>
                    );
                  })}
                </Carousel>
              </section>
            </Container>
          </div>
        </div>

        <Container
          tag="section"
          className="relative py-12 space-y-8 md:space-y-12 overflow-x-hidden content-visibility"
        >
          <Heading level="h2" font="heading" color={theme === THEME.DEFAULT ? 'secondary-lahemaa' : null}>
            {t('HOME_CATEGORIES')}
          </Heading>

          <Carousel arrowsPosition="top-14">
            {configuration.categories.map((category) => {
              const countKey = {
                et: 'categoryCountsEt',
                ru: 'categoryCountsRu',
              }[router.locale];
              const count = configuration[countKey]?.[category];

              return (
                <Link href={`${routes.FILTER[router.locale]}?category=${category}`} key={category}>
                  <a className="space-y-4 group">
                    <figure className="relative aspect-w-3 aspect-h-4">
                      <Image
                        src={getCategoryImage(category)}
                        placeholder="blur"
                        layout="fill"
                        objectFit="cover"
                        alt={t(category)}
                      />
                    </figure>

                    <p
                      className={classNames('font-bold no-underline group-hover:underline', {
                        'text-primary': theme === THEME.DEFAULT,
                      })}
                    >
                      {t(category)}
                    </p>
                    {typeof count === 'number' && (
                      <span
                        className={classNames('text-sm', {
                          'text-gray-hellamaa': theme === THEME.DEFAULT,
                          'text-secondary-soomaa': theme === THEME.CHRISTMAS2022,
                        })}
                      >
                        ({count})
                      </span>
                    )}
                  </a>
                </Link>
              );
            })}
          </Carousel>

          <Separator />
        </Container>

        <Container tag="section" className="space-y-8 md:space-y-12 py-12 overflow-x-hidden content-visibility">
          {configuration.categories.map((category) => (
            <Category category={category} key={category} configuration={configuration} />
          ))}
        </Container>

        <section
          className={classNames('pt-12 content-visibility', {
            'bg-bay-kolga': theme === THEME.DEFAULT,
            'bg-secondary-tamula': theme === THEME.CHRISTMAS2022,
          })}
        >
          <div className="space-y-24">
            <Container className="relative overflow-x-hidden space-y-8 md:space-y-12">
              <Heading
                level="h2"
                font="heading"
                className="md:text-center"
                color={theme === THEME.DEFAULT ? 'white' : 'national-park-vilsandi'}
              >
                {t('REGIONS_TITLE')}
              </Heading>

              <Carousel countDesktop={5}>
                {configuration.regions.map((region) => {
                  const countKey = {
                    et: 'regionCountsEt',
                    ru: 'regionCountsRu',
                  }[router.locale];
                  const count = configuration[countKey]?.[region];

                  return (
                    <Link href={`${routes.FILTER[router.locale]}?region=${region}`} key={region}>
                      <a className="space-y-4 group">
                        <figure className="relative aspect-w-3 aspect-h-4">
                          <Image
                            src={getRegionImage(region)}
                            placeholder="blur"
                            layout="fill"
                            objectFit="cover"
                            alt={t(`REGION_${region}`)}
                          />
                        </figure>

                        <p
                          className={classNames('font-bold no-underline group-hover:underline', {
                            'text-white': theme === THEME.DEFAULT,
                            'text-national-park-vilsandi': theme === THEME.CHRISTMAS2022,
                          })}
                        >
                          {t(`REGION_${region}`)}
                        </p>
                        {typeof count === 'number' && (
                          <span
                            className={classNames('text-sm', {
                              'text-gray-pahkla': theme === THEME.DEFAULT,
                              'text-secondary-alutaguse': theme === THEME.CHRISTMAS2022,
                            })}
                          >
                            ({count})
                          </span>
                        )}
                      </a>
                    </Link>
                  );
                })}
              </Carousel>
            </Container>

            <Container className="relative">
              <SubscribeBackgroundSVG className="absolute bottom-0 left-1/2 -translate-x-1/2" />
              <div className="relative z-[1] pt-32 pb-24 md:px-12 lg:px-44 space-y-12">
                <Heading level="h2" font="heading" position="center">
                  {t('HOME_SUBSCRIBE_TITLE')}
                </Heading>
                <ContactForm className="relative" />
              </div>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  );
}

export async function getStaticProps({ locale }) {
  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return {
      props: {},
    };
  }

  const translations = await BackendClient.getTranslations();
  const configurationResponse = await BackendClient.getConfiguration();

  return {
    props: {
      messages: translations.data[locale],
      configuration: configurationResponse.data,
    },
    revalidate: 60 * 60,
  };
}

export default Home;
