import { useTranslations } from 'next-intl';
import classNames from 'classnames';

import Heading from '@/components/Heading';
import useTheme, { THEME } from '@/hooks/useTheme';

function InstructionSteps() {
  const t = useTranslations();
  const { theme } = useTheme();
  const steps = [
    {
      title: t('HOME_INSTRUCTION_1'),
    },
    {
      title: t('HOME_INSTRUCTION_2'),
    },
    {
      title: t('HOME_INSTRUCTION_3'),
    },
  ];

  return (
    <section
      id="how-is-it-working"
      className={classNames('p-4 md:p-8 space-y-8', {
        'bg-white': theme === THEME.DEFAULT,
        'bg-secondary-lahemaa': theme === THEME.CHRISTMAS2022,
      })}
    >
      <Heading
        level="h2"
        font="heading"
        color={theme === THEME.DEFAULT ? 'primary' : 'secondary-tamula'}
        position="center"
        className="md:w-2/3 md:mx-auto"
      >
        {t('HOME_INSTRUCTION_TITLE')}
      </Heading>

      <ul className="grid grid-cols-1 md:grid-cols-3 gap-8 counter-reset">
        {steps.map((step, index) => (
          <li
            key={index}
            className={classNames(
              'text-center md:text-lg flex flex-col items-center counter-increment before:counter-result before:w-6 before:h-6 before:text-sm before:font-bold before:leading-none before:pt-1 before:rounded-full before:text-center before:flex before:justify-center before:items-center',
              {
                'before:bg-primary before:text-white': theme === THEME.DEFAULT,
                'before:bg-secondary-tamula before:text-national-park-vilsandi': theme === THEME.CHRISTMAS2022,
              },
            )}
          >
            <p className="mt-5">{step.title}</p>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default InstructionSteps;
