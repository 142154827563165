import { useTranslations } from 'next-intl';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

import TargetedCategorySelect from '@/containers/index/TargetedCategorySelect';
import CategorySelect from '@/containers/index/CategorySelect';
import Button from '@/components/Button';
import Heading from '@/components/Heading';
import Carousel from '@/components/Carousel/Carousel';
import BackendClient, { queryKeys } from '@/lib/api';
import { getImage, getRandomIntInclusive } from '@/lib/utils';
import routes from '@/lib/routes';
import BackgroundSVG from '../../assets/svg/generator-background.svg';
import Rahn1SVG from '../../assets/svg/generator-svg-1.svg';
import Rahn2SVG from '../../assets/svg/generator-svg-2.svg';
import Rahn3SVG from '../../assets/svg/generator-svg-3.svg';
import placeholder from '../../assets/images/placeholder.jpg';
import LeftSVG from '../../assets/svg/arrow-left-circle.svg';
import RightSVG from '../../assets/svg/arrow-right-circle.svg';
import Loader from '@/components/Loader';
import useTheme, { THEME } from '@/hooks/useTheme';

function Arrow({ direction, onClick }) {
  const { theme } = useTheme();

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames('hidden md:block absolute top-1/2 -translate-y-1/2 bg-transparent z-[1] hover:opacity-80', {
        'md:-left-12 lg:-left-24': direction === 'prev',
        'md:-right-12 lg:-right-24': direction === 'next',
        'text-primary': theme === THEME.DEFAULT,
        'text-secondary-tamula': theme === THEME.CHRISTMAS2022,
      })}
    >
      <span className="sr-only">{direction}</span>
      {direction === 'prev' ? <LeftSVG /> : <RightSVG />}
    </button>
  );
}

function GiftGenerator({ categories, targetedCategories }) {
  const t = useTranslations();
  const router = useRouter();
  const { theme } = useTheme();
  const [category, setCategory] = useState([]);
  const [targetedCategory, setTargetedCategory] = useState([]);
  const [items, setItems] = useState([]);
  const requestKey = useMemo(() => getRandomIntInclusive(1, 1000), []);
  const gradients = ['from-primary', 'from-contrast-kukka', 'from-secondary-soomaa', 'from-contrast-kuremaa'];
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetch = async ({ pageParam = 0 }) => {
    const { data: response } = await BackendClient.getSellerObjects({
      page: pageParam + 1,
      category,
      targetedCategory,
      language: router.locale,
      requestKey,
      limit: 6,
    });

    return response;
  };
  const { data, isError, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, isLoading, isFetching } =
    useInfiniteQuery([queryKeys.getSellerObjects, category, targetedCategory, router.locale], fetch, {
      getNextPageParam: (lastPage, pages) => lastPage.pagination.page,
    });

  const filterString = useMemo(() => {
    const categories = category.map((s) => ['category', s]);
    const targetedCategories = targetedCategory.map((s) => ['targetedCategory', s]);
    const string = new URLSearchParams([...categories, ...targetedCategories]).toString();

    return string.length ? `?${string}` : '';
  }, [category, targetedCategory]);

  useEffect(() => {
    const allItems = data?.pages
      .map((page) => {
        return page.data;
      })
      .flat();

    if (allItems) {
      setItems(
        allItems.map((item, index) => ({
          ...item,
          color: gradients[index % gradients.length],
        })),
      );
    }
  }, [data]);

  return (
    <section className="relative overflow-hidden -mx-4 pt-16 pb-36" id="gift-generator">
      <BackgroundSVG
        className={classNames('absolute left-1/2 -translate-x-1/2 top-0', {
          'text-gray-pahkla': theme === THEME.DEFAULT,
          'text-secondary-lahemaa': theme === THEME.CHRISTMAS2022,
        })}
      />
      <Rahn1SVG
        className={classNames('absolute top-8 -left-32 sm:-left-12 md:left-32', {
          'text-secondary-kakerdaja': theme === THEME.DEFAULT,
          'text-secondary-alutaguse': theme === THEME.CHRISTMAS2022,
        })}
      />
      <Rahn2SVG
        className={classNames('absolute top-0 right-0', {
          'text-gray-pahkla': theme === THEME.DEFAULT,
          'text-secondary-lahemaa': theme === THEME.CHRISTMAS2022,
        })}
      />
      <Rahn3SVG
        className={classNames('absolute bottom-0 right-8', {
          'text-secondary-tamula': theme === THEME.DEFAULT || theme === THEME.CHRISTMAS2022,
        })}
      />
      <div className="relative z-[1] max-w-3xl mx-auto px-4 space-y-8 lg:space-y-10">
        <div className="space-y-8">
          <div className="space-y-8 md:space-y-14">
            <Heading
              level="h2"
              font="heading"
              color={theme === THEME.DEFAULT ? 'primary' : 'secondary-tamula'}
              position="center"
            >
              {t('HOME_GIFT_GENERATOR_TITLE')}
            </Heading>

            <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2">
                <TargetedCategorySelect
                  name="giftTargetedCategory"
                  categories={targetedCategories}
                  onChange={setTargetedCategory}
                  value={targetedCategory}
                />
              </div>
              <div className="w-full md:w-1/2">
                <CategorySelect name="giftCategory" categories={categories} onChange={setCategory} value={category} />
              </div>

              <Button variant="primary" onClick={refetch} loading={isLoading}>
                {t('HOME_GIFT_GENERATOR_CTA')}
              </Button>

              <p className="md:hidden text-xs">{t('FILTER_SEARCH_RANDOM_ORDER')}</p>
            </div>
          </div>

          <div className="text-center">
            <Button variant="secondary" to={`${routes.FILTER[router.locale]}${filterString}`} disabled={isLoading}>
              {t('HOME_GIFT_GENERATOR_FILTER_CTA')}
            </Button>
          </div>
        </div>

        {isError ? (
          <></>
        ) : isLoading || (isFetching && !isFetchingNextPage) ? (
          <div className="py-24">
            <Loader />
          </div>
        ) : (
          <div className="relative">
            {currentIndex > 0 && <Arrow direction="prev" onClick={() => sliderRef.current.slickPrev()} />}
            <div className="lg:overflow-x-hidden pt-8 pb-8 -mx-4 px-4">
              <Carousel
                ref={sliderRef}
                countDesktop={3}
                slidesToScroll={1}
                countTablet={3}
                slidesToScrollTablet={1}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                hideButtons
                setCurrentIndex={setCurrentIndex}
              >
                {items.map((object, index) => {
                  const isCenter = currentIndex + 1 === index;
                  const image = getImage(object)?.url ?? placeholder;
                  const url = `${routes.DETAIL[router.locale]}/${object.slugs[router.locale]}`;
                  const urlBuy = `${routes.ORDER[router.locale]}?objectId=${encodeURIComponent(object._id)}`;

                  return (
                    <div
                      key={object._id}
                      className={classNames('space-y-4 relative block shadow-lg', {
                        'relative z-[1] lg:scale-125': isCenter,
                      })}
                    >
                      <figure className="relative aspect-w-4 aspect-h-5 md:aspect-h-4">
                        <Image src={image} layout="fill" objectFit="cover" alt={object.title[router.locale]} />
                      </figure>
                      <div className="absolute z-[1] bottom-4 left-4 right-4 space-y-4">
                        <Link href={url}>
                          <a className="no-underline hover:underline text-white">
                            <p>{object.title[router.locale]}</p>
                          </a>
                        </Link>
                        <div
                          className={classNames('flex space-x-2', {
                            'lg:hidden': !isCenter,
                          })}
                        >
                          {object.giftCardLink ? (
                            <Button
                              href={object.giftCardLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              variant="buy"
                              className="gtm-kingiaega-webpage"
                            >
                              {t('BUY')}
                            </Button>
                          ) : (
                            <Button variant="buy" to={urlBuy} className="gtm-kingiaega-buy">
                              {t('BUY')}
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className={classNames('bg-gradient-to-t absolute inset-0', object.color)}></div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <Arrow direction="next" onClick={() => sliderRef.current.slickNext()} />
            <p className="absolute hidden md:block lg:-bottom-10 -bottom-4 left-1/2 -translate-x-1/2 text-xs">
              {t('FILTER_SEARCH_RANDOM_ORDER')}
            </p>
          </div>
        )}
      </div>
    </section>
  );
}

export default GiftGenerator;
