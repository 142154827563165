import { forwardRef } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import LeftSVG from '../../assets/svg/arrow-left-circle.svg';
import RightSVG from '../../assets/svg/arrow-right-circle.svg';
import style from './Carousel.module.css';
import useTheme, { THEME } from '@/hooks/useTheme';

function Arrow({ direction, onClick, arrowsPosition, buttonWidth = 0, variant, ...props }) {
  const { theme } = useTheme();

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        style['carousel__arrow'],
        arrowsPosition,
        'hidden absolute bg-transparent z-[1] hover:opacity-80',
        {
          [style[`is-${direction}`]]: direction,
          ...(theme === THEME.DEFAULT && {
            'text-primary': variant === 'default',
          }),
          ...(theme === THEME.CHRISTMAS2022 && {
            'text-secondary-tamula': variant === 'default',
            'text-national-park-vilsandi': variant === 'dark',
          }),
        },
      )}
      style={{
        '--arrow-display': props.style.display,
        '--button-width': `${buttonWidth + 8}px`,
      }}
    >
      <span className="sr-only">{direction}</span>
      {direction === 'prev' ? <LeftSVG /> : <RightSVG />}
    </button>
  );
}

const Carousel = (
  {
    children,
    countDesktop = 6,
    countTablet = 4.5,
    slidesToScroll,
    slidesToScrollTablet = 4,
    arrowsPosition = 'top-0',
    buttonWidth,
    itemPadding = 'px-2',
    itemClassName,
    hideButtons,
    className,
    hasNextPage,
    fetchNextPage,
    setCurrentIndex,
    variant = 'default',
  },
  ref,
) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: countDesktop,
    slidesToScroll: slidesToScroll ?? countDesktop,
    swipeToSlide: true,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: countTablet,
          slidesToScroll: slidesToScrollTablet,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
    arrows: !hideButtons,
    prevArrow: <Arrow direction="prev" arrowsPosition={arrowsPosition} buttonWidth={buttonWidth} variant={variant} />,
    nextArrow: <Arrow direction="next" arrowsPosition={arrowsPosition} buttonWidth={buttonWidth} variant={variant} />,
    afterChange: (index) => {
      if (index + 4 === children.length && hasNextPage) {
        if (typeof fetchNextPage === 'function') {
          fetchNextPage();
        }
      }
    },
    beforeChange: (current, next) => {
      if (typeof setCurrentIndex === 'function') {
        setCurrentIndex(next);
      }
    },
  };

  return (
    <div className={classNames('-mx-2', className)}>
      <Slider {...settings} ref={ref}>
        {children.map((child, index) => {
          if (!child) {
            return null;
          }

          return (
            <div key={child.key} className={classNames('inline-block text-base', itemPadding, itemClassName)}>
              {child}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default forwardRef(Carousel);
