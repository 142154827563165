import classNames from 'classnames';

import useTheme, { THEME } from '@/hooks/useTheme';

function Separator({ className }) {
  const { theme } = useTheme();

  return (
    <hr
      className={classNames('w-full', className, {
        'border-t-2 border-gray-pahkla': theme === THEME.DEFAULT,
        'border-t border-secondary-alutaguse': theme === THEME.CHRISTMAS2022,
      })}
    />
  );
}

export default Separator;
