import { forwardRef } from 'react';
import { useField } from 'formik';
import classnames from 'classnames';
import xss from 'xss';

import { xssWhitelist, hasValue } from '@/lib/utils';
import useTheme, { THEME } from '@/hooks/useTheme';

const FORMIK_KEYS = ['touched', 'initialTouched', 'initialValue', 'initialError'];

const Checkbox = forwardRef(
  ({ error, id, label, className, labelClassName, value, showError, variant = 'default', ...props }, ref) => {
    const { theme } = useTheme();
    const safeProps = { ...props };
    FORMIK_KEYS.forEach((key) => delete safeProps[key]);

    const fieldValue = hasValue(value) ? value : false;

    return (
      <div>
        <div className={classnames('relative', className)}>
          <input type="checkbox" ref={ref} id={id} className="peer sr-only" value={fieldValue} {...safeProps} />
          <label
            className={classnames(
              'block cursor-pointer text-sm text-white peer-checked:text-primary group pl-10 text-left',
              labelClassName,
            )}
            htmlFor={id}
          >
            <span
              className={classnames(
                'absolute left-0 top-1/2 transform -translate-y-1/2 flex items-center justify-center bg-white border group-hover:border-primary w-6 h-6 leading-none',
                {
                  'border-gray-pahkla': !showError,
                  'border-secondary-toila': showError,
                },
              )}
            >
              <svg
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current stroke-2 h-4 w-4"
              >
                <path d="M1.3335 3.66665L4.66683 6.99998L10.3335 1.33331" />
              </svg>
            </span>
            <span
              className={classnames({
                ...(theme === THEME.DEFAULT && {
                  'text-black': !showError,
                }),
                ...(theme === THEME.CHRISTMAS2022 && {
                  'text-secondary-tamula': !showError && variant === 'default',
                  'text-national-park-vilsandi': !showError && variant === 'dark',
                }),

                'text-secondary-toila': showError,
              })}
              dangerouslySetInnerHTML={{ __html: xss(label, xssWhitelist()) }}
            />
          </label>
        </div>
        {showError && (
          <p
            className="mt-2 text-xs text-secondary-toila"
            dangerouslySetInnerHTML={{
              __html: xss(error || '', xssWhitelist()),
            }}
          />
        )}
      </div>
    );
  },
);

export const FormikCheckbox = forwardRef(({ doNotShowError, ...props }, ref) => {
  const [field, meta] = useField({ name: props.name, type: 'checkbox' });
  const showError = !doNotShowError && meta.touched && !!meta.error;

  if (!props.name) {
    console.warn('FormikCheckbox is missing a name.');
    return null;
  }

  return <Checkbox {...field} {...meta} {...props} ref={ref} showError={showError} />;
});

export default Checkbox;
