import Head from 'next/head';

import Heading from './Heading';
import Logo from '@/components/Header/Logo';
import Button from './Button';

function Maintenance() {
  return (
    <>
      <Head>
        <title>Kingi Aega kampaania on lõppenud.</title>
      </Head>

      <div className="space-y-32">
        <div className="max-w-[75rem] mx-auto px-4 md:px-16 flex justify-end">
          <Logo />
        </div>
        <div className="max-w-xl mx-auto px-4 text-center space-y-12">
          <Heading visualLevel="h3+">Kingi Aega kampaania on lõppenud.</Heading>
          <p>
            Kui sul on küsimusi soetatud kinkekaartide kohta, siis palun pöördu klienditoe poole aadressil{' '}
            <a href="mailto:kingiaega@puhkaeestis.ee">kingiaega@puhkaeestis.ee</a>
          </p>
          <Button href="https://www.puhkaeestis.ee">
            puhkaeestis.ee
          </Button>
        </div>
      </div>
    </>
  );
}

export default Maintenance;
