import { Menu, MenuItems, MenuButton, MenuPopover } from '@reach/menu-button';
import { positionMatchWidth } from '@reach/popover';
import { useId } from '@reach/auto-id';
import classNames from 'classnames';

import Checkbox from './Checkbox';
import useTheme, { THEME } from '@/hooks/useTheme';

function SelectMenu({ label, emptyLabel, name, items, onChange, variant = 'default' }) {
  const { theme } = useTheme();
  const id = useId();
  const selectedItems = items.filter((item) => item.checked);
  const hasSelectedItems = selectedItems.length > 0;
  const selectedLabel = hasSelectedItems ? selectedItems.map((item) => item.label).join(', ') : emptyLabel;

  return (
    <Menu>
      {({ isExpanded }) => (
        <div>
          <p
            className={classNames('text-xs font-bold', {
              'text-primary': isExpanded,
              ...(theme === THEME.CHRISTMAS2022 && {
                'text-national-park-vilsandi': variant === 'dark',
              }),
            })}
          >
            {label}
          </p>
          <MenuButton
            id={id}
            className={classNames(
              'flex justify-between w-full border-b overflow-hidden leading-loose h-8 text-left text-sm ',
              {
                ...(theme === THEME.DEFAULT && {
                  'text-gray-majakivi': !hasSelectedItems,
                  'text-black': hasSelectedItems,
                  'border-gray-hellamaa': !isExpanded,
                }),
                ...(theme === THEME.CHRISTMAS2022 && {
                  'text-secondary-tamula': variant === 'default',
                  'text-national-park-vilsandi': variant === 'dark',
                  'text-national-park-vilsandi': variant === 'dark',
                  'border-secondary-tamula': !isExpanded && variant === 'default',
                  'border-national-park-vilsandi': !isExpanded && variant === 'dark',
                }),
                'border-primary': isExpanded,
              },
            )}
          >
            {selectedLabel}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              width="1em"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
              className="mt-2"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </MenuButton>
          <MenuPopover position={positionMatchWidth}>
            <MenuItems className="relative z-20 overflow-y-auto max-h-96 bg-white border border-gray-pahkla shadow-md space-y-4 py-4 px-2">
              {items.map((item) => (
                <div key={item.value}>
                  <Checkbox
                    id={`item-${name}-${item.value}`}
                    name={name}
                    label={item.label}
                    value={item.value}
                    checked={item.checked}
                    variant="dark"
                    onChange={onChange}
                  />
                </div>
              ))}
            </MenuItems>
          </MenuPopover>
        </div>
      )}
    </Menu>
  );
}

export default SelectMenu;
