import LoadingSVG from '../assets/svg/loading.svg';

function Loader() {
  return (
    <div className="flex justify-center">
      <LoadingSVG className="h-16 w-16 animate-spin text-primary" />
    </div>
  );
}

export default Loader;
